import React, { Component, Fragment } from "react"
import ScrollToTop from "react-scroll-up"
import Particles from "react-particles-js"
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi"
import ServiceList from "../elements/service/ServiceList"
import BlogContent from "../elements/blog/BlogContent"
import FooterTwo from "../component/footer/FooterTwo"
import Contact from "../elements/contact/Impress"

import SEO from "../components/seo"
import Header from "../component/header/HeaderFour"

import TextLoop from "react-text-loop";
import AboutTwo from "../component/HomeLayout/homeOne/AboutTwo"
import ServiceWrapper from "../elements/service/ServiceWrapper"
import CounterOne from "../elements/counters/CounterOne"
import Accordion01 from "../elements/Accordion"
import Team from "../blocks/Team"
import TabThree from "../elements/tab/TabThree"
import Lightbox from "react-image-lightbox"
import { TabPanel } from "react-tabs"
import ContactTwo from "../elements/contact/ContactTwo"
import Impress from "../elements/contact/Impress"
import Footer from "../component/footer/Footer"
import Testimonial from "../elements/Testimonial"
import BrandTwo from "../elements/BrandTwo"


const PortfolioList = [
  {
    image: 'maskenversand',
    name: 'Maskenversand',
    title: 'Der Onlineshop für Arbeitsschutzprodukte'
  },
  {
    image: 'agrano',
    name: 'Agrano Fleet Management GmbH',
    title: 'Effiziente und professionelle Fuhrparkverwaltung'
  },
  {
    image: 'vehicl',
    name: 'VEHICL',
    title: 'VEHICL transformiert den stationären Automobilhandel in ein digitales Geschäftsmodell.'
  },
  {
    image: 'adm',
    name: 'ADM Logistics GmbH',
    title: 'Full-Service-Logistik aus einer Hand.'
  }
]


const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "Netzwerk Digitaler Unternehmen",
    description: "Kompetent und professionell unterstützen wir Sie dabei, Ihr Geschäftsmodell um eine digitale Perspektive zu erweitern.",
    buttonText: "Unser Portfolio",
    buttonLink: "#portfolio"
  }
]

class HomeParticles extends Component {
  constructor(props) {
    super(props)
    this.menuTrigger = this.menuTrigger.bind(this)
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this)
    this.stickyHeader = this.stickyHeader.bind(this)

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    // window.addEventListener('load', function() {
    //   console.log('All assets are loaded');
    // })
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open")
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open")
  }

  stickyHeader() {
  }

  render() {
    if (typeof window !== `undefined`) {
      window.addEventListener("scroll", function() {
        var value = window.scrollY
        if (value > 100) {
          document.querySelector(".header--fixed").classList.add("sticky")
        } else {
          document.querySelector(".header--fixed").classList.remove("sticky")
        }
      })

      var elements = document.querySelectorAll(".has-droupdown > a")
      for (var i in elements) {
        if (elements.hasOwnProperty(i)) {
          elements[i].onclick = function() {
            this.parentElement.querySelector(".submenu").classList.toggle("active")
            this.classList.toggle("open")
          }
        }
      }
    }

    return (
      <Fragment>
        <SEO title="EdelNetzwerk"/>

        {/* Start Header Area  */}
        <Header headerPosition="header--transparent" color="color-white" logo="logo-light"/>
        {/* End Header Area  */}


        <Header homeLink="/" logo="symbol-dark" color="color-black"/>

        <div className="slider-activation">
          {/* Start Single Slide */}
          <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--25" data-black-overlay="6">
            <div className="container position-relative">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner">
                    <div className="rn-about-area ptb--120 bg_color--5">
                    <Impress></Impress>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Single Slide */}
        </div>
        {/* Start Slider Area   */}


      <FooterTwo></FooterTwo>
      </Fragment>
    )
  }
}

export default HomeParticles
